:root {
  .primaryButton {
    background-color:#671e75;
    border-color: #671e75;
    border-radius: 1.25em;
    color: #fff;
  }
  .primaryButton:focus, .primaryButton:active {
    box-shadow: rgba(103, 30, 117, 0.68) 0 0 0 1px;
    border-color: rgba(103, 30, 117, 0.68);
    background-color: rgba(103, 30, 117, 0.68);
  }
}

.ilh-btn-primary {
  border: unset;
  color: #fff;
  font-size: 15px;
  background-color: var(--iconColorPrimary);
  vertical-align: middle;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  &:focus {
    outline: 0;
    box-shadow: unset;
  }
  &:disabled {
    background-color: #50505099;
    cursor: not-allowed;
  }
}

.ilh-btn-light {
  border: unset;
  color: var(--iconColorPrimary);
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0);
  vertical-align: middle;
  &:focus {
    outline: 0;
  }
  &:disabled {
    cursor: not-allowed;
    color: #50505099;
  }
  &:active:enabled {
    color: #50505099;
  }
}
