@import 'scss/color.scss';
@import 'scss/button.scss';
@import 'scss/checkbox.scss';
@import 'scss/field-validation.scss';
@import 'scss/ilh-input-group.scss';

html, body {
  height: 100%;
  overflow-y: hidden;
  margin: 0;
}

.toast-top-center {
  top: 1rem;
}

.alert {
  margin-bottom: 0;
}

hr {
  border-top-width: 1px;
  margin: 0.25rem 0 0.75rem 0;
}

.field_text {
  font-weight: bold;
}

.lead {
  font-size: 1rem;
}

.underline {
  text-decoration : underline;
}

.bold {
  font-weight : bold;
}

.italics {
  font-style: italic;
}

.required {
  color: #b02300;
}

.gci-button {
  text-transform: none;
  font-weight: 400;
  font-family: Roboto, Helvetica, sans-serif;
}

.gci-button-inverse {
  background-color: white !important;
  color: #671e75 !important;
  border: 2px solid #671e75;
  border-radius: 0.25rem;
  font-weight: 400;
  font-family: Roboto, Helvetica, sans-serif;
}

.section-title {
  font-family: 'Calibri Light', Helvetica, sans-serif !important;
  color: #671e75 !important;
}

.btn-primary {
  color: #fff;
  background-color: #671e75;
  border-color: #671e75;
  outline: none !important;
}

.btn-primary:hover {
  background-color: #671e75;
  border-color: #671e75;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #671e75;
  border-color: #671e75 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: #671e75 !important;
  background-color: #671e75;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-primary:disabled {
  background-color: #671e75;
  border-color: #671e75;
}

.btn-outline-primary {
  border-color: #671e75;
  color: #671e75;
}

.btn-outline-primary:hover:not(.disabled) {
  color: #fff;
  background-color: var(--primaryTextColor);
  border-color: var(--primaryTextColor);
}

.btn-outline-primary:disabled {
  color: var(--primaryTextColor);
  opacity: .75;
}

.border-transparent {
  border: 2px solid transparent;
}

.border {
  border-width: 2px !important;
}

.border-danger {
  border-width: 2px !important;
  border-color: #b02300 !important;
}

.border-success {
  border-width: 2px !important;
  border-color: #671e75 !important;
}

.btn-danger {
  color: white !important;
  background-color: #af2529 !important;
  border-color: transparent !important;
}

.cursor-crosshair {
  cursor: crosshair;
}

.bs-datepicker-body table td.week span
{
  color: #671e75 !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover
{
  background-color: #671e75 !important;
}

@media screen and (min-width: 451px) {
  label, input, input::placeholder, textarea, textarea::placeholder, .form-control, .btn, .text-sizing> div > input::placeholder {
    font-size: 1rem;
  }
  .h3 {
    font-size: 1.75rem;
  }
  .h4 {
    font-size: 1.4rem;
  }
  .h5 {
    font-size: 1.2rem;
  }
  .h6 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 450px) {
  label, input, input::placeholder, textarea, textarea::placeholder, .form-control, .btn, .text-sizing > div > input::placeholder {
    font-size: 0.85rem;
  }
  .h3 {
    font-size: 1.33rem;
  }
  .h4 {
    font-size: 1.15rem;
  }
  .h5 {
    font-size: 1rem;
  }
  .h6 {
    font-size: 0.8rem;
  }
}

.form-control {
  caret-color: #671e75;
}

li.condition-tab {
  width: 25%;
  border-top: 5px solid #d5d9db;
  border-right: 1px solid #bbbdbe;
}

li.condition-tab span {
  width: calc(85% - 10px);
  margin-left: 5px;
  text-align: left;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tooltip-inner {
  background-color: #4C4C4C;
  border: solid 2px black;
}

.tooltip.show {
  opacity: 1;
}

/* New version of dnd added a default class, we're removing it here */
.ngx-dnd-container, .ngx-dnd-container:nth-child(odd) {
  border: none;
  background-color: unset;
  padding: unset;
  margin: unset;
}

.toast-warning, .toast-error, .toast-success {
  width: 450px !important;
}

.bottom-0 {
  bottom: 0;
}

.modal-full {
  max-width: 110vh;
}

.modal-iop-signature-prompt {
  width: 95%;
  min-width: 600px;
}

.modal-full .ql-editor {
  height: 50vh;
}

.modal-full-width {
  max-width: 95vw;
  min-width: 300px;
}

.modal-form-view {
  width: 90vw;
  max-width: 1200px;
  min-width: 300px;
  height: 100%;
}

.modal-form-view > .modal-content {
  height: 100%;
  display: inherit;
}

.modal-narrow {
  max-width: 540px;
  min-width: 500px;
}

.full-screen-modal {
  max-width: 90vw;
  max-height: 90vh;
  min-height: 400px;
  height: 100%;
}

.full-screen-modal > .modal-content {
  height: 100%;
}

.modal-confirmation > .modal-content {
  background: #d7d7d7;
  border-radius: 1rem;
  max-width: 300px;
  margin: auto;
}

.modal-confirmation {
  top:calc(50% - 210px);
}

.modal > .hl7-sender-modal {
  max-width: 90vw;
  max-height: 90vh;
  min-height: 400px;
}

.modal > .hl7-sender-modal > .modal-content {
  max-width: 90vw;
  max-height: 90vh;
  min-height: 400px;
}

.modal > .modal-barcode-scanner {
  margin: 0!important;
  position: fixed!important;
  max-width: 100vw;
  width: 100vw;
}

.modal > .modal-barcode-scanner > .modal-content {
  max-height: 100vh;
  height: 100vh;
}

.modal > .modal-manage-tags > .modal-content {
  max-width: 540px;
  min-width: 500px;
  max-height: 600px;
}

.modal > .modal-manage-tags > .modal-content > next-manage-tags {
  height: 100%;
}

.modal > .modal-notification-bell > .modal-content {
  max-width: 540px;
  min-width: 500px;
  min-height: 200px;
  max-height: 300px;
  height: 80vh;
}

.modal > .modal-appointment-setting > .modal-content {
  min-width: 600px;
  min-height: 200px;
  height: 50vh;
}

.modal > .modal-access-code-prompt > .modal-content {
  padding-top: 30px;
  min-width: 600px;
  min-height: 240px;
}

.modal-content {
  /* this is an overwrite of the bootstrap modal class */
  max-height: 90vh;
  white-space: pre-wrap;
}

.modal-open .modal {
  /* this is an overwrite of the bootstrap modal class .modal-open .modal */
  overflow-y: hidden!important;
}

#appointments-calendar .p-calendar {
  transform: scale(0.75);
}

p-tableCheckbox .p-checkbox .p-checkbox-box, p-tableHeaderCheckbox .p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  padding: 10px;
  background: #fff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 15px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

p-tableCheckbox .p-checkbox .p-checkbox-box.p-highlight, p-tableHeaderCheckbox .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #671e75;
  background: #671e75;
}

p-tableCheckbox .p-checkbox .p-checkbox-box.p-highlight:hover, p-tableHeaderCheckbox .p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: #671e75;
  color: #fff;
}

p-tableCheckbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover, p-tableHeaderCheckbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #671e75;
  background: #671e75;
  color: #fff;
}

#ih-field-mappings-map ng-select > .ng-select-container, #sf-field-mappings-map ng-select > .ng-select-container {
  background-color: #f1f1f1;
}

#ih-field-mappings-map ng-select.ng-invalid > .ng-select-container, #sf-field-mappings-map ng-select.ng-invalid > .ng-select-container {
  background-color: rgba(255, 0, 0, 0.1);
  border-color: rgba(255, 0, 0, 0.33);
}

.p-datatable-scrollable-body {
  overflow-y: auto !important;
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #671e75;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  padding: 10px;
  background: #fff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 15px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--iconColorPrimary);
  background: var(--iconColorPrimary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--iconColorPrimary);
  color: #fff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--iconColorPrimary);
  background: var(--iconColorPrimary);
  color: #fff;
}

.data-mapping-tooltip > .tooltip-inner, .library-data-table-tooltip > .tooltip-inner {
  max-width: 700px;
  min-width: 300px;
}

// header
.ng-select.select-header.ng-select-opened >.ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent white;
  border-width: 0 5px 5px;
}

.ng-select.select-header .ng-arrow-wrapper .ng-arrow {
  border-color: white transparent transparent;
  color: white!important;
}

#selectFacility .ng-select.ng-select-opened>.ng-select-container  {
  background: #671e75;
}

#selectFacility .ng-select-container {
  color:white;
  background-color: #671e75;
}

#selectFacility .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color:white;
  background-color: #671e75;
}

#selectFacility .ng-clear {
  color:white;
  background-color: #671e75;
}

#selectDepartment .ng-select.ng-select-opened > .ng-select-container {
  background: #671e75;
}

#selectDepartment .ng-select-container {
  color: white;
  background-color: #671e75;
}

#selectDepartment .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: white;
  background-color: #671e75;
}

#selectDepartment .ng-clear {
  color: white;
  background-color: #671e75;
}

// patient
#selectPatient .ng-select-container {
  color:black;
  background-color: #f8f8f8;
}

#selectPatient .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color:white;
  background-color: #671e75;
}

#selectPatient.ng-select-opened .ng-select-container .ng-arrow-wrapper .ng-arrow  {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

#selectPatient .ng-select-container .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

// appointment
#selectAppointment .ng-select-container {
  color:black;
  background-color: #f8f8f8;
}

#selectAppointment .ng-value-container {
  font-weight: normal;
}

#selectAppointment .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-weight: normal;
}

#selectAppointment .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color:white;
  background-color: #671e75;
  font-weight: normal;
}

#selectAppointment.ng-select-opened .ng-select-container .ng-arrow-wrapper .ng-arrow  {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

#selectAppointment .ng-select-container .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

// Appointment calendar popover
.next-appointment-date-range-popover {
  max-width: unset !important;
  border: none;
}

.next-appointment-date-range-popover > .popover-content {
  color: unset;
  padding: 0;
}

.next-appointment-date-range-popover.bs-popover-right {
  margin-left: 2rem;
  margin-top: 4rem;
}

.next-appointment-date-range-popover .bs-datepicker-predefined-btns > button {
  background-color: white;
  border: 1px solid #671E75;
  color: #671E75;

  &.selected {
    background-color: #671E75;
    color: white;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    background-color: #671e75;
    color: white;
  }
}

// picklist
.p-picklist-buttons > button {
  background-color: #671E75;
  border: 1px solid #671E75;
}

.element-richtext .ql-editor
{
  text-align: center;
  min-height: 200px;
  display: grid;
  align-items: center;
}

#form-organization-setup-folders-tree .p-tree {
  border: none!important;
}

#form-organization-setup-folders-tree .p-tree .p-treenode-droppoint {
  height: 1em!important;
  border-radius: 0.25em;
  margin: .1em  0 .1em 0;
}

#form-organization-setup-folders-tree .p-tree .p-treenode-children {
  padding: 0 0 0 2rem!important
}

p-dialog .access-code-prompt > .p-dialog-content, p-dialog .access-code-prompt > .p-dialog-header, p-dialog .access-code-prompt > .p-dialog-footer {
  background: #F4F4F4;
}

// Patch for primeng table flex scrolling bug
// https://github.com/primefaces/primeng/issues/9025#issuecomment-962521371
.p-datatable-flex-scrollable .p-datatable-wrapper {
  flex-basis: auto !important;
  height: 0 !important;
}

// Prevents text from being selected by the user
.no-select {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.search-text-highlight {
  background-color: blue;
  color: white;
  padding: 0.1em;
}

.appointments-accordion {
  .p-accordion-content {
    padding: 0!important;
  }

  .p-accordion-header {
    .p-accordion-header-link {
      border: 1px solid #671e7580 !important;
      cursor: default;
      padding: 0 !important;

      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        text-decoration: none;
      }
    }
    .p-accordion-toggle-icon {
      display: none;
    }
  }
}

.p-badge {
  background: #671e75 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.color-primary {
  color: var(--primaryTextColor);
}

/* https://css-tricks.com/keyboard-only-focus-styles */
.tab-focus-visible {
  &:focus {
    &:focus-visible {
      outline: 2px solid black;
      border-radius: 3px;
    }
  }
}

@font-face {
  font-family: "Brush Script MT";
  src: url("./assets/fonts/BRUSHSCI.TTF");
}

.font {
  font-family: 'Brush Script MT';
}
