
:root 
{
    .primary-checkbox-round {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 15px;
        width: 15px;
    }
}