// for standardizing input-group used for search bar
$ilh-input-group-bg-color: #E4E4E6;
.ilh-input-group {
  border: 0;
  .ilh-input-field {
    border: 0;
    padding-left: 8px;
    padding-right: 8px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: $ilh-input-group-bg-color;
  }
  .ilh-input-side {
    span .ilh-bar-child:not(:first-child) {
      padding-left: 5px;
    }
    .btn {
      border: 0;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

/* side before input (left) */
.ilh-input-group .ilh-input-side:has( + .ilh-input-field),
.ilh-input-group .ilh-input-side:has( + .ilh-input-field) span {
  border: 0;
  margin-right: 0;
  padding-right: 0;
  background: $ilh-input-group-bg-color;
}
.ilh-input-group .ilh-input-side:has( + .ilh-input-field) span {
  padding-left: 8px;
}

/* side after input (right) */
.ilh-input-group .ilh-input-field + .ilh-input-side,
.ilh-input-group .ilh-input-field + .ilh-input-side span {
  border: 0;
  margin-left: 0;
  padding-left: 0;
  background: $ilh-input-group-bg-color;
}
.ilh-input-group .ilh-input-field + .ilh-input-side span {
  padding-right: 8px;
}

