// for form-field-validation directive (matches with bootstrap's validation)
$field-invalid-red: #DC3545;
$field-valid-green: #28A745;
.field-invalid {
  border-color: $field-invalid-red !important;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 88, 19, 0.5) !important;
  }
}
.field-invalid-glow {
  border-color: $field-invalid-red !important;
  box-shadow: 0 0 0 0.2rem rgba(191, 88, 19, 0.5) !important;
}

.field-valid {
  border-color: $field-valid-green !important;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 196, 89, 0.5) !important;
  }
}
.field-valid-glow {
  border-color: $field-valid-green !important;
  box-shadow: 0 0 0 0.2rem rgba(23, 196, 89, 0.5) !important;
}
