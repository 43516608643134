:root {
  // header
  --primaryHeaderColor: #ffffff;

  // icon
  --iconColorPrimary: #671e75;
  --iconColorSecondary: #50505099;
  --iconColorLightGray: #d4d4d4;

  // menu
  --menuBackgroundColor: #50505015;
  --activeMenuBackgroundColor: #50505099;

  // text
  --primaryTextColor: #671e75;

  // checkbox
  --primaryCheckboxBackground: #C6C6C8;
  --primaryCheckboxBorder: #C6C6C8;

  // generic
  --colorPrimary: #671e75;
  --colorSuccess: #4ED70080;
  --colorDanger: #DC3545;
}
